const slider = new Swiper('.slider', {
  effect: 'fade',
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
});

const pickup = new Swiper('.pickup-container', {
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

$(function () {
  const newsList = document.getElementById('caution-news-list');
  const newsMaxHeight = newsList.firstElementChild.clientHeight;
  newsList.style.maxHeight = `${newsMaxHeight}px`;

  $('.btn-caution').on('click', function () {
    $(this).parent('.caution-container').toggleClass('is-open');

    if ($(this).parent('.caution-container').hasClass('is-open')) {
      const maxHeight = newsList.scrollHeight;
      newsList.style.maxHeight = `${maxHeight}px`;
    } else {
      newsList.style.maxHeight = `${newsMaxHeight}px`;
    }
  });
});
